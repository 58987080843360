#cc-main {
    --cc-bg: #F7F7F7;
    --cc-primary-color: #383838;
    --cc-secondary-color: #383838;

    --cc-btn-primary-bg: #A90F16;
    --cc-btn-primary-color: #FFFFFF;
    --cc-btn-primary-border-color: #B81030;
    --cc-btn-primary-hover-bg: #B50E19;
    --cc-btn-primary-hover-color: #FFFFFF;
    --cc-btn-primary-hover-border-color: #A40D2A;

    --cc-separator-border-color: #F0F4F7;

    --cc-toggle-on-bg: #B81030;

    --cc-footer-bg: #F7F7F7;
    --cc-footer-color: #383838;
    --cc-footer-border-color: #B81030;
}
